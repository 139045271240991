import { BASE_URL } from "./Constant";
import request from "../requests";
import { func } from "prop-types";

function login(data) {
  return request({
    url: `${BASE_URL}/login`,
    method: 'POST',
    data
  })
};

function logout() {
  return request({
    url: `${BASE_URL}/logout`,
    method: 'POST'
  })
};

function forgotPassword(data) {
  return request({
    url: `${BASE_URL}/forgot-password`,
    method: 'PATCH',
    data
  })
};

function getUserById(id) {
  return request({
    url: `${BASE_URL}/get-user`,
    method: 'GET',
  })
}

function updateUser(data) {
  return request({
    url: `${BASE_URL}/update-user`,
    method: 'PATCH',
    data
  })
}

function verifyResetPasswordToken(token) {
  return request({
    url: `${BASE_URL}/verify-update-password/${token }`,
    method: 'GET',
  })
};

function updatePassword(data) {
  return request({
    url: `${BASE_URL}/update-password`,
    method: 'PATCH',
    data
  })
};


function getDiseases(page=0, size=11) {
  return request({
    url: `${BASE_URL}/get-all-diseases?page=${page}&size=${size}`,
    method: 'GET',
  })
}

function getDisease(diseaseUUID) {
  return request({
    url: `${BASE_URL}/get-disease/${diseaseUUID}`,
    method: 'GET',
  })
}

function addDisease(data) {
  return request({
    url: `${BASE_URL}/create-disease`,
    method: 'POST',
    data
  })
}

function editDisease(diseaseUUID, data) {
  return request({
    url: `${BASE_URL}/update-disease/${diseaseUUID}`,
    method: 'PUT',
    data
  })
}

function getDiseaseLiterature(diseaseUUID) {
  return request({
    url: `${BASE_URL}/get-disease-interest-growth/${diseaseUUID}`,
    method: 'GET',
  })
}

function getDatasets(diseaseUUID, page=0, size=11, search = '') {
  let url = `${BASE_URL}/get-all-datasets/${diseaseUUID}?page=${page}&size=${size}`;
  if (search != '') {
    url += `&search=${search}`
  }
  return request({
    url: url,
    method: 'GET',
  })
}

function getAnalysis(diseaseUUID, page = 0, size = 11, search = '') {
  let url = `${BASE_URL}/get-all-analysis/${diseaseUUID}?page=${page}&size=${size}`;
  if (search != '') {
    url += `&search=${search}`
  }
  return request({
    url: url,
    method: 'GET',
  })
}

function getAnalysisDetails(analysisUUID) {
  return request({
    url: `${BASE_URL}/get-analysis-detail/${analysisUUID}`,
    method: 'GET',
  })
}

function getProteinOfAnalysis(analysisUUID, page = 0, size = 11, search = '') {
  let url = `${BASE_URL}/get-all-proteins/${analysisUUID}?page=${page}&size=${size}`;
  if (search != '') {
    url += `&search=${search}`
  }
  return request({
    url: url,
    method: 'GET',
  })
}

function getProteinDetails(proteinUUID) {
  return request({
    url: `${BASE_URL}/get-protein-detail/${proteinUUID}`,
    method: 'GET',
  })
}

function getProteinLiterature(diseaseUUID, proteinUUID) {
  return request({
    url: `${BASE_URL}/get-protein-interest-growth/${diseaseUUID}/${proteinUUID}`,
    method: 'GET',
  })
}

function getDiseaseBarChart(diseaseUUID, proteinUUID) {
  return request({
    url: `${BASE_URL}/get-disease-barchart/${diseaseUUID}/${proteinUUID}`,
    method: 'GET',
  })
}

function getMLScores(diseaseUUID, analysisUUID, proteinUUID) {
  return request({
    url: `${BASE_URL}/get-machine-learning-info/${diseaseUUID}/${analysisUUID}/${proteinUUID}`,
    method: 'GET',
  })
}

function getPublicationsList(diseaseUUID, proteinUUID, page = 0, size = 11, search = '') {
  let url = `${BASE_URL}/get-publications-list/${diseaseUUID}/${proteinUUID}?page=${page}&size=${size}`;
  if (search != '') {
    url += `&search=${search}`
  }
  return request({
    url: url,
    method: 'GET',
  })
}

function getInteractionBasicDetails(analysisUUID, proteinUUID) {
  return request({
    url: `${BASE_URL}/get-interactions-basic-details/${analysisUUID}/${proteinUUID}`,
    method: 'GET',
  })
}

function getInteractionGraph(diseaseUUID, analysisUUID, proteinUUID, nodeSize = 25, isSignificant = false) {
  return request({
    url: `${BASE_URL}/get-interactions-graph/${diseaseUUID}/${analysisUUID}/${proteinUUID}?nodeSize=${nodeSize}&isSignificant=${isSignificant}`,
    method: 'GET',
  })
}

function getProteinMetaboliteInteractions(analysisUUID, proteinUUID, nodeSize = 25, isSignificant = false) {
  return request({
    url: `${BASE_URL}/get-protein-phenotype-interactions/${analysisUUID}/${proteinUUID}?nodeSize=${nodeSize}&isSignificant=${isSignificant}`,
    method: 'GET',
  })
}

function getInteractionTableList(diseaseUUID, analysisUUID, proteinUUID, page = 0, size = 11, search = '') {
  let url = `${BASE_URL}/get-interactions-table/${diseaseUUID}/${analysisUUID}/${proteinUUID}?page=${page}&size=${size}`;
  if (search != '') {
    url += `&search=${search}`
  }
  return request({
    url: url,
    method: 'GET',
  })
}

function checkForInteractions() {
  return request({
    url: `${BASE_URL}/check-for-interactions-upload`,
    method: 'GET',
  })
}

function interactionsUploadLastDate() {
  return request({
    url: `${BASE_URL}/get-last-interactions-upload-date`,
    method: 'GET',
  })
}

function getFilters(diseaseId, analysisId){
  return request({
    url: `${BASE_URL}/get-distinct-filters/${diseaseId}/${analysisId}`,
    method: 'GET',
  })
}

function checkForFileNames(data){
  return request({
    url: `${BASE_URL}/check-for-file-names`,
    method: 'POST',
    data
  })
}

function getDistinctFilters(data, diseaseId, analysisId, page=0, size=25, search = '', makeExcelFile = 0){
  let url = `${BASE_URL}/get-proteins-for-filters/${diseaseId}/${analysisId}?page=${page}&size=${size}`;
  if (search != '') {
    url += `&search=${search}`
  }
  if (makeExcelFile) {
    url += `&makeFile=1`
  }
  return request({
    url: url,
    method: 'POST',
    data
  })
}

const cardiaTech = {
  login,
  logout,
  forgotPassword,
  verifyResetPasswordToken,
  updatePassword,
  getUserById,
  updateUser,
  getDiseases,
  getDisease,
  addDisease,
  editDisease,
  getDiseaseLiterature,
  getDatasets,
  getAnalysis,
  getAnalysisDetails,
  getProteinOfAnalysis,
  getProteinDetails,
  getProteinLiterature,
  getDiseaseBarChart,
  getMLScores,
  getPublicationsList,
  getInteractionBasicDetails,
  getInteractionGraph,
  getProteinMetaboliteInteractions,
  getInteractionTableList,
  checkForInteractions,
  interactionsUploadLastDate,
  getFilters,
  checkForFileNames,
  getDistinctFilters
};

export default cardiaTech;
