import { Navigate, Route, Routes } from "react-router-dom";
import { lazy, Suspense, useEffect, useState } from "react";
import App from "../App";
import RoutesConstant from "./Constant";
import { useAuthContext } from "../hooks/useAuthContext";

const Login = lazy(() => import("../Pages/Login"));
const Forgot = lazy(() => import("../Pages/Forgot"));
const ForgotSuccess = lazy(() => import("../Pages/Forgot/Success"));
const Update = lazy(() => import("../Pages/Update"));
const UpdateSuccess = lazy(() => import("../Pages/Update/Success"));
const UpdateFail = lazy(() => import("../Pages/Update/Fail"));
const Diseases = lazy(() => import("../Pages/Diseases"));
const Explore = lazy(() => import("../Pages/Explore"));
const Details = lazy(() => import("../Pages/Details"));
const Profile = lazy(() => import("../Pages/Profile"));
const Graph = lazy(() => import("../Pages/Interactions"));
const NotFound = lazy(() => import("../Components/NotFound"));
const Auth = lazy(() => import("../Components/Auth"));

function AppRoutes() {
  const [loading, setLoading] = useState(false);
  const { user, dispatch } = useAuthContext();

  return (
    <Suspense
      fallback={
        <div className="lds-ripple loader">
          <div></div>
        </div>
      }
    >
      <Routes>
        <Route element={<Auth />}>
          <Route element={<App />}>
            <Route path={RoutesConstant.main} element={<Diseases />} />
            <Route path={RoutesConstant.exploreDisease} element={<Explore />} />
            <Route path={RoutesConstant.proteinDetails} element={<Details />} />
            <Route path={RoutesConstant.userProfile} element={<Profile />} />
            <Route path={RoutesConstant.graph} element={<Graph />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Route>

        <Route path={RoutesConstant.login} element={<Login />} />
        <Route path={RoutesConstant.forgotPassword} element={<Forgot />} />
        <Route path={RoutesConstant.forgotPasswordSuccess} element={<ForgotSuccess />} />
        <Route path={RoutesConstant.resetPassword} element={<Update />} />
        <Route
          path={RoutesConstant.resetPasswordSuccess}
          element={<UpdateSuccess />}
        />
        <Route
          path={RoutesConstant.resetPasswordFail}
          element={<UpdateFail />}
        />
      </Routes>
    </Suspense>
  );
}
export default AppRoutes;
