import axios from 'axios'
import cookie from "react-cookies";
import RoutesConstant from '../Routes/Constant';

const request = function (options) {
    const onSuccess = function (response) {
        return response.data;
    };

    const onError = function (err) {
        if (err) {
         
            if (err?.response?.status===401) {
                cookie.remove('id', {path: '/'});
                cookie.remove('token', {path: '/'});
                cookie.remove('name', {path: '/'});
                cookie.remove("is_admin", { path: "/" });
                cookie.remove('messageShown', {path: '/'});
                window.location.href = RoutesConstant.login;
            }

            if(err?.response?.status===431||err?.response?.status==500){
                console.error('Data:', err);
            }

            if (err?.response?.status === 404) {
                window.location.href = RoutesConstant.main        
            }
            //Request was made but server responded with something other than 2xx
            console.error('Status:', err?.response?.status);
            console.error('Data:', err?.response?.data);
            console.error('Headers:', err?.response?.headers);
         
        } else {
            // Something else happened while setting up the request triggered the error
            console.error('Error Message:', err?.response?.data);
        }

        //return Promise.reject(err.response || err.message);
        return Promise.reject(err?.response?.data);
    };
    const client = axios.create({
      headers: {
        pragma: 'no-cache',
        token: cookie.load('token'),
        id: cookie.load('id')
      }
    });

    return client(options)
        .then(onSuccess)
        .catch(onError);
};

export default request;