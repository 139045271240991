const RoutesConstant = {
    main:'/',
    login: '/login',
    forgotPassword: '/forgot-password',
    forgotPasswordSuccess: '/forgot-password-success',
    resetPassword: '/reset-password/:token',
    resetPasswordSuccess: '/reset-password/success',
    resetPasswordFail: '/reset-password/fail',
    exploreDisease: '/explore-disease/:diseaseId',
    proteinDetails: '/protein-details/:analysisId',
    userProfile: '/user-profile',
    graph: '/graph',
    validateFileEndpoint: '/validate-file', // Backend Api to validate the upload files
    uploadFileEndpoint: '/upload-file', // Backend Api to upload the upload files
    validateInteractionsEndpoint: '/validate-interactions-file', // Backend Api to validate the interactions files
    uploadInteractionsEndpoint: '/upload-interactions-file' // Backend Api to upload the interactions files
}

export default RoutesConstant