// For Local
//export const BASE_URL = 'http://localhost:3001/api';

// For Staging
//export const BASE_URL = 'http://3.138.240.20:9002/api';

// For Live
export const BASE_URL = 'https://api.cardiatec.ai/api';

// For Local
//export const BACKEND_URL = 'http://localhost:3001/';

// For Staging
//export const BACKEND_URL = 'http://3.138.240.20:9002/';

// For Live
export const BACKEND_URL = 'https://api.cardiatec.ai/';
