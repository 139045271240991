
import { Outlet, useNavigate } from "react-router-dom";
import "./App.css";
import AppHeader from "./Components/AppHeader";
import { useAuthContext } from "./hooks/useAuthContext";
import RoutesConstant from "./Routes/Constant";

function App() {
    const navigate = useNavigate();

    const { user } = useAuthContext();
    if (user == null || user?.id == null || user?.id == undefined || user?.token == null || user?.token == undefined) {
        navigate(RoutesConstant.login)
    }

    return (
            <div className="App">
                <div id="layout-wrapper">
                    <div className="main-content">
                        <AppHeader />
                        <div className="page-content">
                            <div className="container-fluid pb-2">

                                    <Outlet />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
}
export default App;
