import { BellFilled, MailOutlined } from "@ant-design/icons";
import { Badge, Image, Space, Modal, notification, Skeleton } from "antd";
import {  useState } from "react";
import { useAuthContext } from '../../hooks/useAuthContext';
import { LOGOUT_ACTION } from '../../Context/Actions'
import RoutesConstant from "../../Routes/Constant";
import { Link, useNavigate } from "react-router-dom";
import cardiaTech from "../../Services/Api/Api";
import cookie from "react-cookies";
import { BASE_URL } from "../../Services/Api/Constant";
import { message, Upload } from 'antd';

const { Dragger } = Upload;


function AppHeader() {

    const navigate = useNavigate();

    const [api, contextHolder] = notification.useNotification();
    const [uploadModal, setUploadModal] = useState(null);
    const [showError, setShowError] = useState(null);
    const [showErrorResponse, setShowErrorResponse] = useState([]);
    const [uploadUUID, setUploadUUID] = useState(null);
    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

  const { dispatch, user } = useAuthContext();

  const logout=()=>{
    cardiaTech.logout()
    .then((res) => {
        console.log(res)
    })
    .catch((err) => {
        console.log(err)
    })
    cookie.remove('id', {path: '/'});
    cookie.remove('token', {path: '/'});
    cookie.remove('name', {path: '/'});
    cookie.remove("messageShown", { path: "/" });
    cookie.remove("is_admin", { path: "/" });

   dispatch({ type: LOGOUT_ACTION, payload: null });
   navigate(RoutesConstant.login)
};

const showUploadModal = () => {
    setUploadModal(true);
  };

const handleCancel = () => {
    setIsModalOpen(false)
}

const refreshUploadModal = () => {
    setFileList([])
    setShowError(null)
    setUploading(false)
    setShowErrorResponse([])
    setUploadUUID(null)
  } 

const cancelHandlerUpload = () => {
    setUploadModal(false);
    setFileList([])
    setUploading(false)
    setShowError(null)
    setShowErrorResponse([])
    setUploadUUID(null)
  };

  const handleUpload = () => {
    setUploading(true);
    cardiaTech.checkForInteractions()
    .then((res) => {
        console.log(res);
        setUploading(false)
        if (res?.result) {
            setIsModalOpen(true)
        }
        else {
          handleFileName()
        }
    })
    .catch((err) => {
        setUploading(false)
        console.log(err)
    })
  }

  const handleFileName = () => {
    setUploading(true);
    setIsModalOpen(false);
    let fileNames = []
    fileList.forEach((file) => {
      fileNames.push(file.name);
    });
    let data = {
      fileNames,
      'type': 'interactions'
    }
    cardiaTech.checkForFileNames(data)
      .then((res) => {
        setUploading(false);
        if (res?.result?.missing_files) {
          setShowError(true)
          setShowErrorResponse(res?.result)
        }
        else {
          handleUploadNow();
        }
      })
      .catch((err) => {
        setUploading(false);
        console.log(err)
      })
  };

  const handleUploadNow = () => {
    setIsModalOpen(false);
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append('files', file);
    });
    formData.append('timestamp', Date.now())
    setUploading(true);
    // You can use any AJAX library you like
    fetch(BASE_URL + RoutesConstant.validateInteractionsEndpoint, {
      method: 'POST',
      headers: {
        pragma: 'no-cache',
        token: cookie.load('token'),
        id: cookie.load('id')
      },
      body: formData,
    })
      .then((res) => {
        return res.json(); // Parse response body as JSON
      })
      .then((data) => {
        console.log(data);
        setUploadUUID(data?.upload_uuid)
        if (data && data.result.Errors) {
          if (data.result.Errors.length > 0) {
            setShowError(true)
          }
          else {
            setShowError(false)
          }
          setShowErrorResponse(data.result)
        }
        else if (data && data.result.error) {
          setShowError(true)
          setShowErrorResponse(data.result)
          message.error('Something went wrong.');
        }
        else {
          setFileList([]);
          setShowError(false)
        }
        // Continue processing the response data
      })
      .catch(() => {
        message.error('upload failed.');
      })
      .finally(() => {
        setUploading(false);
      });
  };

  const handleUploadSuccess = () => {
    const body = JSON.stringify({ upload_uuid: uploadUUID });
    setUploading(true);
    // You can use any AJAX library you like
    fetch(BASE_URL + RoutesConstant.uploadInteractionsEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        pragma: 'no-cache',
        token: cookie.load('token'),
        id: cookie.load('id')
      },
      body: body,
    })
      .then((data) => {
        setUploadModal(false);
        setFileList([])
        setShowError(null)
        setShowErrorResponse([])
        setUploadUUID(null)
        api.success({
          //message: `Success`,
          description: "We'll notify you via email once the Interactions Data File has been uploaded successfully.",
          placement: "topRight",
        });
      })
      .catch(() => {
        api.error({
          //message: `Success`,
          description: "Error in uploading the file.",
          placement: "topRight",
        });
      })
      .finally(() => {
        setUploading(false);
      });
  };

  const removeFile = (file) => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
}

  const props = {
    name: 'file',
    multiple: true,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      const fileExt = file.name.split('.').pop(); // Get the file extension
      const isCSV = fileExt.toLowerCase() === 'csv'; // Check if the file extension is CSV

      if (!isCSV) {
        message.error('You can only upload CSV files!');
        return false; // Prevent automatic file upload
      }

      const duplicateFiles = fileList.filter((f) => f.name === file.name); // Check for duplicate files
      if (duplicateFiles.length > 0) {
        for (let i=0; i<duplicateFiles.length; i++) {
          message.error(`The same file name "${duplicateFiles[i]?.name}" already exists!`);
          return false; // Prevent automatic file upload          
        }
      }

      setFileList((prevFileList) => [...prevFileList, file]);
      return false; // Prevent automatic file upload
    },
    fileList: showError == null ? fileList : [],
    accept: '.csv', // Specify accepted file types as CSV
  };
  

  return (
    <header id="page-topbar">
        <div className="navbar-header">
        {contextHolder}
            <Link to={RoutesConstant.main} className="d-flex">
            <img src={process.env.PUBLIC_URL + '/assets/images/CardiaTec-logo.png'} alt="logo" className="logo" width="150"/>
            </Link>
            <div className="d-flex">

                <div className="dropdown d-inline-block">
                    <button type="button" className="btn dropdown-toggle"
                            data-bs-toggle="dropdown"  aria-expanded="false">

                        <img className="header-profile-user ms-2" src={process.env.PUBLIC_URL + '/assets/images/avatar.svg'}
                             alt="Header Avatar"/>
                        <span className="d-inline-block mx-2 user-name"><small>{user?.name || 'Undefined'}</small></span>
                        <i className="fa fa-angle-down caret-icon"></i>
                    </button>
                    <div className="dropdown-menu dropdown-menu-right">

                                <Link to={
                                    RoutesConstant.userProfile
                                } className="dropdown-item profile-sidebar-menu"><i className="fa fa-user mr-2"></i> Profile</Link>
                                {
                                    user.is_admin == 1 ? <a onClick={() => {
                                        setUploadModal(true)
                                    }} className="dropdown-item" style={{cursor: 'pointer'}}><i className="fa  fa-upload mr-2"></i> Upload Interactions</a> : ''
                                }
                                <a  onClick={logout} className="dropdown-item" style={{cursor: 'pointer'}}><i className="fa  fa-sign-out mr-2"></i> Log Out</a>
                    </div>
                </div>
            </div>
            <Modal
            centered
        maskClosable={false}
        className="upload-modal"
        id="upload-modal"
        destroyOnClose={true}
        open={uploadModal}
        closable={true}
        onCancel={() => cancelHandlerUpload(false)}
        okButtonProps={{ className: "d-none" }}
        cancelButtonProps={{ className: "d-none" }}
        title={"Upload Interactions Data"}
      >
        {
          uploading ?
          //  <Skeleton loading={uploading} avatar={true} />
          <div className="text-center py-4"> <i className="fa fa-spinner fa-spin" style={{fontSize:"36px"}}></i> <p className="mt-2">Please wait, while the data is being validated.</p></div>
           : (
            <div className={showError == null ? '' : showError ? 'uploadError' : 'uploadSuccess'}>
              <h3 className="form-sub-heading">

                {showError == null ? (
                  <>
                    Click the box to select and upload your <strong className="csv-text">.csv files</strong> or just drop your <strong className="csv-text">.csv files</strong> here to upload.
                  </>
                )

                  : showError ? (
                    <>
                      Click on Upload Again to proceed with uploading the file(s) without any errors.
                    </>
                  ) :
                    (
                      <>
                        Click on Upload Now to complete uploading the file(s).
                      </>
                    )
                }

              </h3>
              <div className="row">
                <div className="col-12">
                  {/* upload */}
                  <Dragger disabled={showError === null ? false : showError ? true : true} {...props} className="upload-files-area">
                    <p className="ant-upload-drag-icon mb-2">
                      {showError == null ? <img src="../assets/images/upload-icon.svg" alt="" height='60' /> : showError ? <img src="../assets/images/upload-error-icon.svg" alt="" height='60' /> : <img src="../assets/images/upload-img-icon.svg" alt="" height='60' />}
                    </p>
                    <p className="ant-upload-text">

                      {showError == null ? 'Drag and drop the .csv files here' : showError ? 'Validation Unsuccessful' : 'Validation Successful'}

                    </p>
                  </Dragger>
                </div>
              </div>

              {
                showError && (
                  <div className="row error-list">
                    <div className="col-12">
                    <ul>
                        {
                          showErrorResponse && (
                            <>
                             <div className="popup-scroll-wrap">
                            {fileList && fileList.map((f,i) => {
                              let className = ''
                              if (!showErrorResponse?.missing_files) {
                                className = 'file-name-green'
                                if (showErrorResponse?.error_files.includes(f?.name)) {
                                  className = "file-name-red"
                                }
                              }
                              return (
                                <>
                                <div className="d-flex align-items-center pb-1 w-50">
                                <svg className="me-2" viewBox="64 64 896 896" focusable="false" data-icon="paper-clip" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M779.3 196.6c-94.2-94.2-247.6-94.2-341.7 0l-261 260.8c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l261-260.8c32.4-32.4 75.5-50.2 121.3-50.2s88.9 17.8 121.2 50.2c32.4 32.4 50.2 75.5 50.2 121.2 0 45.8-17.8 88.8-50.2 121.2l-266 265.9-43.1 43.1c-40.3 40.3-105.8 40.3-146.1 0-19.5-19.5-30.2-45.4-30.2-73s10.7-53.5 30.2-73l263.9-263.8c6.7-6.6 15.5-10.3 24.9-10.3h.1c9.4 0 18.1 3.7 24.7 10.3 6.7 6.7 10.3 15.5 10.3 24.9 0 9.3-3.7 18.1-10.3 24.7L372.4 653c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l215.6-215.6c19.9-19.9 30.8-46.3 30.8-74.4s-11-54.6-30.8-74.4c-41.1-41.1-107.9-41-149 0L463 364 224.8 602.1A172.22 172.22 0 00174 724.8c0 46.3 18.1 89.8 50.8 122.5 33.9 33.8 78.3 50.7 122.7 50.7 44.4 0 88.8-16.9 122.6-50.7l309.2-309C824.8 492.7 850 432 850 367.5c.1-64.6-25.1-125.3-70.7-170.9z"></path></svg>
                                <div className={className}>{f?.name}</div>
                                {/* <svg onClick={() => {removeFile(f)}} className="ms-2 icon-delete" viewBox="64 64 896 896" focusable="false" data-icon="delete" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z"></path></svg> */}
                                </div>
                                </>
                                ) 
                            })}
                            </div>
                              {showErrorResponse.missing_files === true && (
                                <li className="d-block my-2"><strong>Following Data Files are missing:</strong></li>
                              )}
                              {showErrorResponse.Errors && showErrorResponse.Errors.map((err, i) => (
                                <li key={i}>{i + 1}. {err}</li>
                              ))}
                              {showErrorResponse?.error && (
                                <li>Something went wrong:</li>
                              )}
                            </>
                          )
                        }

                      </ul>
                    </div>
                  </div>
                )
              }


              <div className="row">
                <div className="col-12 text-end">
                  {
                    showError == null ?
                      (
                        <>
                          <button onClick={handleUpload} disabled={uploading || fileList.length == 0} className="btn btn-primary">
                            <img src="../assets/images/upload.svg" alt="" /> Validate
                          </button>
                        </>
                      ) :
                      showError ? (
                        <>
                          <button onClick={refreshUploadModal} disabled={uploading || fileList.length == 0} className="btn btn-primary">
                            <img src="../assets/images/upload.svg" alt="" /> Upload Again
                          </button>
                        </>
                      )
                        : (
                          <>
                            <button onClick={handleUploadSuccess} disabled={uploading || fileList.length == 0} className="btn btn-primary">
                              <img src="../assets/images/upload.svg" alt="" /> Upload Now
                            </button>
                          </>
                        )
                  }

                </div>
              </div>

            </div>
          )
        }

      </Modal>
      <Modal className="confirmation-message" destroyOnClose={true} maskClosable={true} closable={true} okText={'Yes'} cancelText={'No'}  title="Confirmation Message" open={isModalOpen} onOk={() => handleFileName()} onCancel={handleCancel}>
                <p className="confirmation-text">The data for Interactions has been uploaded already, do you want to replace the existing file with the new one? </p>
        </Modal>
        </div>
    </header>
  );
}
export default AppHeader;
