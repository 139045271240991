import { createContext, useReducer } from 'react';
import { UPDATE_FILTERS, RESET_FILTERS } from './Actions';

export const FiltersContext = createContext()

export const filtersReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_FILTERS:
      return { ...state, filters: action.payload }
    case RESET_FILTERS:
      return { ...state, filters: null }
    default:
      return state
  }
}

export const FiltersContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(filtersReducer, {
    filters: null
  })

  console.log('FiltersContext state:', state)

  return (
    <FiltersContext.Provider value={{ ...state, dispatch }}>
      {children}
    </FiltersContext.Provider>
  )

}